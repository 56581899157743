<template>
  <div>
    <!-- 菜单管理 -->
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button
          type="success"
          size="small"
          icon="el-icon-plus"
          @click="form.pid = 0, form.pName = '根目录', handleForm(false)"
          v-if="OA.includes('xtgl:cdgl:addFrist')"
          >添加一级菜单</el-button
        >
      </div>
      <!-- <div v-if="roleTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div> -->
      <el-table
        border
        :data="menuList"
        v-loading="tLoading"
        row-key="id"
        :tree-props="{ children: 'children' }"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="title"
          label="菜单名称"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="pid"
          label="菜单级别"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.pid == 0 ? "一级菜单" : "二级菜单" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="path"
          label="菜单路由"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="icon"
          label="菜单图标"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="permission"
          label="权限标识"
        ></el-table-column>
        <el-table-column label="操作" width="140" align="right">
          <template slot-scope="scope">
            <el-button
              v-if="OA.includes('xtgl:cdgl:add')"
              size="mini"
              type="success"
              title="新增"
              @click="handleForm(false, scope.row, afterHandleForm)"
              icon="el-icon-plus"
            ></el-button>
            <el-button
              v-if="OA.includes('xtgl:cdgl:editor')"
              type="primary"
              size="mini"
              title="编辑"
              @click="handleForm(true, scope.row)"
              icon="el-icon-edit"
            ></el-button>
            <el-button
              v-if="OA.includes('xtgl:cdgl:del')"
              type="danger"
              size="mini"
              title="删除"
              @click="handleDelete({id: scope.row.id})"
              icon="el-icon-delete"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog
      :title="isEditor ? '编辑菜单' : '添加菜单'"
      :visible.sync="aVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100px"
        ref="formFileds"
        :model="form"
        :rules="formRules"
        size="small"
      >
        <el-form-item :label="form.type == 1 ? '菜单名称：' : '按钮名称：'" prop="title">
          <el-input clearable size="small" v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="类型：" prop="type">
          <el-radio-group
            v-model="form.type"
            :disabled="isEditor == 1"
          >
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2" :disabled="form.pid == 0">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上级菜单：" prop="pName">
          <el-input
            clearable
            v-model="form.pName"
            disabled
          ></el-input>
        </el-form-item>
        <div v-if="form.pid !== 0 && form.type == 1">
          <el-form-item label="菜单图标：" prop="icon">
            <el-input
              clearable
              v-model="form.icon"
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单路由：" prop="path">
            <el-input
              clearable
              v-model="form.path"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="排序：" prop="sort">
          <el-input-number
            :min="1"
            :max="100"
            v-model="form.sort"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="权限标识：" prop="permission">
          <el-input
            clearable
            v-model="form.permission"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-radio-group
            v-model="form.status"
          >
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示：" prop="visible">
          <el-radio-group
            v-model="form.visible"
          >
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="bLoading"
          @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)"
          >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from "@/common/utils";
export default {
  name: "menuManagement",
  components: {
    PagedTable,
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {},

      form:{
        id:'',
        appId:0,
        title:'',
        icon:'',
        path:'',
        permission:'',
        type: 1,
        parentType: 1,
        pid: 0,
        pName: '根目录',
        status: 1,
        visible: 1,
        sort: 10,
      },
      formRules: {
        title: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        permission: [
          { required: true, message: "请输入权限标识", trigger: "blur" },
        ],
      },

      pageApi: "commonMenuGetAllMenuTree",
      insertApi: "commonMenuAdd",
      editorApi: "commonMenuEdit",
      deleteApi: "commonMenuDelete",
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemMenu", ["menuList", "menuTotal"]),
  },
  methods: {
    ...mapActions("systemMenu", [
      "commonMenuGetAllMenuTree",
      "commonMenuAdd",
      "commonMenuEdit",
      "commonMenuDelete",
    ]),

    afterHandleForm(row){
      this.form.pid = row.id
      this.form.pName = row.title
    }
  },
  async mounted() {
    if (this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name));
    this.refresh();
  },
};
</script>